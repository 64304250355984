import { memo, ReactNode } from 'react';

import RichText from '@/components/RichText/RichText';

export interface Props {
  align?: 'left' | 'center' | 'right';
  className?: string;
  textColor?: string;
  content: string & ReactNode;
  j3Hyphen?: boolean;
  anchor?: string;
}

function BlockParagraph({ className, align, content, textColor, j3Hyphen, anchor }: Props) {
  return (
    <RichText
      className={className}
      tag="p"
      anchor={anchor}
      align={align}
      textColor={textColor}
      j3Hyphen={j3Hyphen}
    >
      {content}
    </RichText>
  );
}

export default memo(BlockParagraph);
